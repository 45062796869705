import React from "react"
import Layout from "components/layout"
import ViewCareers from "components/ViewCareers";
import usePageData from 'hooks/queryCareers.vi';

const CareersPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewCareers pageData={pageData} />
    </Layout>
  )
}

export default CareersPage
