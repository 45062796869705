import React from 'react'
import SEO from "components/seo"
import Heading from "components/Heading"
import HeroImageFull from "components/HeroImageFull";
import Interweave from 'interweave';

const ViewCareers = ({pageData}) => {
  const  { page, heading, careerBlock } = pageData;

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--careers--hero">
        <HeroImageFull
          heroTextClassname="mobile--is-center"
          imageName="careersHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="is-heading">{heading}</Heading>
        </HeroImageFull>
      </div>

      <div className="max-container wrapper--careers--block-content">
        <div className="container max-1048">
          <div className="columns is-multiline">
            <div className="column is-full">
              <Heading>{careerBlock.heading}</Heading>
              <Interweave
                content={careerBlock.content}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewCareers